import { AreaChartOutlined, DashboardOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';
import { CarbonReport, Export, Log, Monitoring, PrimeFileExport, SettingLight } from 'components/icons';
import useDarkMode from 'hooks/useDarkMode';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import getLogo from 'utils/getLogo';

const DrawerMenu = () => {
  const [isDarkMode] = useDarkMode();
  const [visible, setVisible] = useState(false);
  const generalView = useSelector((state) => state.settings.views);
  const { drillerViews, clientLogo, settings } = useSelector((state) => state.settings);
  const isUpdatingDrillerMenu = window.location.pathname.includes('driller-menu');
  const { user } = useSelector((state) => state.general);
  const [position, setPosition] = useState({ top: window.innerHeight / 2 }); // State for vertical position
  const isDragging = useRef(false); // To track if the button is being dragged
  const logo = getLogo(clientLogo, settings, isDarkMode);
  const toggleDrawer = () => {
    if (!isDragging.current) {
      setVisible(!visible);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  const listOfViews = window.location.pathname.includes('create-view')
    ? [...generalView, ...drillerViews]
    : !window.location.pathname.includes('driller-menu')
    ? generalView
    : drillerViews;

  const selectedViewId =
    _.find(listOfViews, { name: isUpdatingDrillerMenu ? user.selected_view_driller : user.selected_view })?.id ||
    _.find(listOfViews, {
      name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
    })?.id;

  const closeDrawer = () => {
    setVisible(false);
  };

  const items = [
    {
      key: '1',
      icon: <SettingLight color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
      label: (
        <Link className="w-full" to="/settings" onClick={closeDrawer}>
          <div className="-ml-1">settings</div>
        </Link>
      ),
    },
    {
      key: '5',
      icon: <HistoryOutlined className="p-0 m-0 text-xl" color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
      label: (
        <a href="/historical" className="pl-1 w-full" onClick={closeDrawer}>
          historical
        </a>
      ),
    },
    {
      key: '2',
      icon: <DashboardOutlined className="p-0 m-0 text-xl" color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
      label: (
        <Link className="w-full" to="/driller-menu" onClick={closeDrawer}>
          <div className="pl-1">driller’s menu</div>
        </Link>
      ),
    },
    {
      key: '7',
      icon: <AreaChartOutlined className="p-0 m-0 text-xl" color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
      label: (
        <Link className="w-full" to="/tank-menu" onClick={closeDrawer}>
          <div className="pl-1">tank menu</div>
        </Link>
      ),
    },
    {
      key: '6 ',
      icon: <Monitoring color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
      label: (
        <Link className="w-full" to="/monitoring" onClick={closeDrawer}>
          <div className="pl-2">monitoring</div>
        </Link>
      ),
    },
    {
      key: '3',
      icon: <Log color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
      label: (
        <Link className="w-full" to="/logs" onClick={closeDrawer}>
          <div className="pl-1">Logs</div>
        </Link>
      ),
    },
    // {
    //   key: '4',
    //   icon: <Export color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
    //   label: (
    //     <span className="pl-1">
    //       <Link to="/export" className="dark:hover:text-white w-full" onClick={closeDrawer}>
    //         Export File
    //       </Link>
    //     </span>
    //   ),
    // },
    // {
    //   key: '8',
    //   icon: <Export color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
    //   label: (
    //     <span className="pl-1">
    //       <a
    //         href={selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page'}
    //         className="dark:hover:text-white"
    //         onClick={closeDrawer}
    //       >
    //         Visual Report
    //       </a>
    //     </span>
    //   ),
    // },
    // {
    //   key: '4',
    //   icon: <Export color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />,
    //   label: <span>Export</span>,
    //   children: [
    //     {
    //       key: '4-1',
    //       icon: <PrimeFileExport color={isDarkMode ? '#FFFFFF' : undefined} />,
    //       label: (
    //         <span className="pl-1">
    //           <Link to="/export" className="dark:hover:text-white w-full" onClick={closeDrawer}>
    //             Export File
    //           </Link>
    //         </span>
    //       ),
    //     },
    //     {
    //       key: '4-2',
    //       icon: <CarbonReport color={isDarkMode ? '#FFFFFF' : undefined} />,
    //       label: (
    //         <span className="pl-1">
    //           <a
    //             href={selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page'}
    //             className="dark:hover:text-white"
    //             onClick={closeDrawer}
    //           >
    //             Visual Report
    //           </a>
    //         </span>
    //       ),
    //     },
    //   ],
    // },
  ];

  // Dragging functionality for vertical movement
  const handleMouseDown = (e) => {
    const startY = e.clientY;
    const startTop = position.top;
    isDragging.current = false; // Reset dragging state

    const handleMouseMove = (moveEvent) => {
      isDragging.current = true; // Set to true if the mouse is moved
      const newTop = startTop + (moveEvent.clientY - startY);
      const buttonHeight = 70; // The height of the button
      const clampedTop = Math.max(0, Math.min(newTop, window.innerHeight - buttonHeight)); // Clamping within viewport
      setPosition({ top: clampedTop });
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      setTimeout(() => {
        isDragging.current = false; // Reset dragging state after mouse is released
      }, 0);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="ant-drawer-menu bg-dark capitalize font-semibold">
      <Drawer
        title={
          <div className="text-xl font-semibold text-gray-800 dark:text-white">
            <img
              src={`${process.env.REACT_APP_API_MEDIA}${logo}`}
              className="lg:h-[43px] w-full mt-2 object-cover"
              alt="main logo saxon"
            />
          </div>
        }
        placement="left"
        closable={false}
        onClose={toggleDrawer}
        visible={visible}
        getContainer={false}
        style={{ zIndex: 9999, width: '200px' }}
        bodyStyle={{
          padding: '0',
          backgroundColor: isDarkMode ? '#141414' : '#FFFFFF',
          width: '200px',
        }}
      >
        <ul className="flex flex-col space-y-2 py-3 pl-4">
          {/* Reduced space between items */}
          {items.map((item) => (
            <li
              key={item.key}
              className={`flex items-center space-x-3 p-2 rounded-lg transition-transform duration-300 ease-in-out hover:scale-105 
              ${isDarkMode ? 'hover:bg-gray-300' : 'hover:bg-gray-100'}`}
              // Dark mode hover background changed to a darker shade
            >
              {item.icon}
              {item.label}
            </li>
          ))}
          <Menu
            mode="inline"
            className='antd-drawer-menu p-0 m-0 -mt-2'
            theme={isDarkMode ? '#141414' : '#1C1C1C'}
            onClick={closeDrawer}
          >
            <Menu.SubMenu
              key="4"
              icon={<Export style={{ minWidth: '20px' }} color={isDarkMode ? '#FFFFFF' : '#1C1C1C'} />}
              title="Export"
            >
              <Menu.Item
                key="4-1"
                icon={<PrimeFileExport style={{ minWidth: '20px' }} color={isDarkMode ? '#FFFFFF' : undefined} />}
              >
                <Link to="/export">Export File</Link>
              </Menu.Item>
              <Menu.Item
                key="4-2"
                icon={<CarbonReport style={{ minWidth: '20px' }} color={isDarkMode ? '#FFFFFF' : undefined} />}
              >
                <a href={selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page'}>Visual Report</a>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </ul>
      </Drawer>
      {!visible && (
        <Button
          className="font-semibold text-lg capitalize"
          onMouseDown={handleMouseDown}
          onClick={toggleDrawer}
          style={{
            position: 'fixed',
            left: '-8px',
            top: `${position.top}px`,
            paddingRight: '10px',
            transform: 'translateY(-50%)',
            backgroundColor: isDarkMode ? '#333' : '#fff',
            borderRadius: '0 7px 7px 0',
            zIndex: 10000,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            writingMode: 'vertical-rl',
            height: '70px',
            width: '32px',
            transition: 'background-color 0.3s ease-in-out, transform 0.3s ease-in-out', // Smooth transitions for hover
          }}
        >
          menu
        </Button>
      )}
    </div>
  );
};

export default DrawerMenu;
